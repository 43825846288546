const moment = require("moment-timezone");

const initState = {
  currentTime: moment.tz(new Date(), moment.tz.guess()).format()
};

const timer = (state = initState, action) => {
  switch (action.type) {
    case "SET_CURRENT_LOCAL_TIME":
      return Object.assign({}, state, { currentTime: action.payload });

    default:
      return state;
  }
};

export default timer;
