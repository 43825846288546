const initState = {
  competition: "All Competitions",
  competitionId: "",
  featured: null,
  inplay: null,
  upcoming: null,
  fixtures: null,
  market: "1x2",
  date: new Date(),
  activeView: "",
  matchStats: [],
  showMatch: false,
  fixtureid: "",
  multipleMatchStats: []
};

const football = (state = initState, action) => {
  switch (action.type) {
    case "SET_COMPETITION":
      return Object.assign({}, state, {
        competition: action.payload
      });

    case "SET_COMPETITION_ID":
      return Object.assign({}, state, {
        competitionId: action.payload
      });

    case "SET_FEATURED_DATA":
      return Object.assign({}, state, {
        featured: action.payload
      });

    case "SET_INPLAY_DATA":
      return Object.assign({}, state, {
        inplay: action.payload
      });

    case "SET_UPCOMING_DATA":
      return Object.assign({}, state, {
        upcoming: action.payload
      });

    case "SET_FIXTURES_DATA":
      return Object.assign({}, state, {
        fixtures: action.payload
      });

    case "SET_MARKET":
      return Object.assign({}, state, {
        market: action.payload
      });

    case "SET_DATE":
      return Object.assign({}, state, {
        date: action.payload
      });

    case "SET_ACTIVE_VIEW":
      return Object.assign({}, state, {
        activeView: action.payload
      });

    case "SET_MATCH_STATS": {
      return Object.assign({}, state, {
        matchStats: action.payload
      });
    }

    case "SET_MULTIPLE_MATCH_STATS": {
      return {
        ...state,
        multipleMatchStats: {
          ...state.multipleMatchStats,
          [action.fixtureid]: action.payload
        }
      };
    }

    case "SET_SHOW_MATCH":
      return Object.assign({}, state, {
        showMatch: action.payload
      });

    case "SET_FIXTURE_ID":
      return Object.assign({}, state, {
        fixtureid: action.payload
      });

    default:
      return state;
  }
};

export default football;
