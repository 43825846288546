import { combineReducers, createStore } from "redux";
import app from "./app";
import betslip from "./betslip";
import timer from "./timer";
import yourbets from "./yourbets";
import matchpreview from "./match";
import football from "./football";
import alert from "./alert";

export const reducers = combineReducers({
  app,
  betslip,
  timer,
  matchpreview,
  football,
  yourbets,
  alert,
});

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
/* eslint no-underscore-dangle: 0 */
export default store;
