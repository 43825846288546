import React, { useState, lazy, Suspense, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Router, navigate } from "@reach/router";
import { connect, useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { PageWrapper, LoadingSpinner } from "styles";
import GlobalStyles from "styles/globalStyles/index";
import { defaultTheme } from "constants/index";
import * as api from "utils/api";

const FootballApp = React.lazy(() => import("layouts/Football/src/layouts"));
const AlertPopup = React.lazy(() =>
  import("layouts/Football/src/components/SameMatchAlert")
);

const Match = lazy(() => import("layouts/Match"));

function App() {
  const footballStore = useSelector((state) => state.football);
  const alertStore = useSelector((state) => state.alert);

  const [data, setData] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchInitialData = async () => {
      const res = await api.getInitialBetData();

      if (res.data) {
        setData(res.data);
      }
    };
    fetchInitialData();
  }, []);

  const isAllowedOrigin = (origin) => {
    const supportedOrigins = [
      "localhost:3000",
      "fa.local",
      "footyaccumulators.com",
      // re-platform generated url support
      "checkd-blogs-v3",
      // re-platform prod url support
      "fa.checkd-media",
      // replatform staging url support
      "fa.test.checkd-media",
      // replatform dev url support
      "fa.dev.checkd-media",
    ];
    // if we're on localhost we don't want to check the origin
    // as we can't receive messages, the supported origin above is for the parent
    if (window.location && window.location.hostname === "localhost")
      return false;
    return supportedOrigins.some((allowedOrigin) =>
      origin.includes(allowedOrigin)
    );
  };

  useEffect(() => {
    const receiveMessage = (event) => {
      if (!isAllowedOrigin(event.origin)) {
        return;
      }
      const received = JSON.parse(event.data);
      if (received.type === "SET_ACTIVE_ODDS_FORMAT") {
        dispatch({
          type: received.type,
          payload: received.oddsFormat.toLowerCase(),
        });
      } else {
        dispatch({
          type: received.type,
          payload: received.betslip,
        });
      }
    };

    const parentURL = document.referrer
      ? document.referrer
      : window.location.ancestorOrigins[0];
    window.addEventListener("message", receiveMessage, false);
    if (window && window.parent) {
      window.parent.postMessage(
        {
          type: "IFRAME_LOADED",
        },
        parentURL
      );
    }
    return () => window.removeEventListener("message", receiveMessage, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={defaultTheme}>
        <PageWrapper>
          <Suspense fallback={<LoadingSpinner />}>
            <Router>
              <FootballApp path="/*" data={data} />
              {footballStore.showMatch && (
                <Match
                  path="/match"
                  data={data}
                  backLink={() =>
                    setTimeout(() => {
                      dispatch({
                        type: "SET_SHOW_MATCH",
                        payload: false,
                      });
                      navigate("/");
                    }, 250)
                  }
                  default
                />
              )}
            </Router>
          </Suspense>
          <Suspense fallback={<LoadingSpinner />}>
            <AlertPopup
              show={alertStore.show}
              msg={alertStore.msg}
              alertDispatchAction={alertStore.dispatchAction}
            />
          </Suspense>
        </PageWrapper>
      </ThemeProvider>
    </>
  );
}

App.propTypes = {
  backLink: PropTypes.func,
  data: PropTypes.object,
};

App.defaultProps = {
  backLink: () => "/",
  data: {},
};

export default connect(null, null)(App);
