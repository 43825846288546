const initState = {
  competition: "",
  homescore:
    localStorage.getItem("stats") !== null
      ? JSON.parse(localStorage.getItem("homescore"))
      : "",
  awayscore:
    localStorage.getItem("stats") !== null
      ? JSON.parse(localStorage.getItem("awayscore"))
      : "",
  statsData:
    localStorage.getItem("stats") !== null
      ? JSON.parse(localStorage.getItem("stats"))
      : "",
  matchLocation: "betting",
  inplayLocation: "betting",
  resultsLocation: "events",
  specialsLocation: "#BUILDABETS",
  bettingLocation: "popular",
  matchindex:
    localStorage.getItem("matchindex") !== null
      ? JSON.parse(localStorage.getItem("matchindex"))
      : "",
};

const matchpreview = (state = initState, action) => {
  switch (action.type) {
    case "SET_HOMESCORE":
      localStorage.setItem("homescore", JSON.stringify(action.payload));

      return Object.assign({}, state, {
        homescore: action.payload,
      });

    case "SET_AWAYSCORE":
      localStorage.setItem("awayscore", JSON.stringify(action.payload));

      return Object.assign({}, state, {
        awayscore: action.payload,
      });

    case "SET_STATS_DATA":
      localStorage.setItem("stats", JSON.stringify(action.payload));

      return Object.assign({}, state, {
        statsData: action.payload,
      });

    case "SET_MATCH_LOCATION":
      return Object.assign({}, state, {
        matchLocation: action.payload,
      });

    case "SET_INPLAY_LOCATION":
      return Object.assign({}, state, {
        inplayLocation: action.payload,
      });

    case "SET_RESULTS_LOCATION":
      return Object.assign({}, state, {
        resultsLocation: action.payload,
      });

    case "SET_SPECIALS_LOCATION":
      return Object.assign({}, state, {
        specialsLocation: action.payload,
      });

    case "SET_BETTING_LOCATION":
      return Object.assign({}, state, {
        bettingLocation: action.payload,
      });

    case "SET_MATCH_INDEX":
      localStorage.setItem("matchindex", JSON.stringify(action.payload));

      return Object.assign({}, state, {
        matchindex: action.payload,
      });

    case "RESET_MATCH_DEFAULTS":
      return Object.assign({}, state, {
        matchLocation: "betting",
        resultsLocation: "events",
        specialsLocation: "#BUILDABETS",
        bettingLocation: "popular",
      });

    default:
      return state;
  }
};

export default matchpreview;
