const initState = {
  selections: [],
  stake: "10",
  tabsInUse: true,
};

const betslip = (state = initState, action) => {
  const parentURL = document.referrer;
  switch (action.type) {
    case "UPDATE_BETSLIP":
      return {
        ...state,
        selections: action.payload,
      };

    case "ADD_SELECTION_TO_BETSLIP":
      const msg = JSON.stringify({
        type: action.type,
        betslip: action.payload,
      });
      if (window && window.parent) {
        window.parent.postMessage(msg, parentURL);
      }

      return {
        ...state,
        selections: [...state.selections, action.payload],
      };

    case "REMOVE_SELECTION_FROM_BETSLIP": {
      const newSelections = [
        ...state.selections.filter(
          (selection) => selection.eventid !== action.payload.eventid
        ),
      ];
      const msg = JSON.stringify({ type: action.type, betslip: newSelections });
      if (window && window.parent) {
        window.parent.postMessage(msg, parentURL);
      }

      return {
        ...state,
        selections: newSelections,
      };
    }

    case "UPDATE_MATCH_SELECTION": {
      const newSelections = state.selections;
      const objIndex = newSelections.findIndex(
        (obj) => obj.eventid === action.payload.eventid
      );

      newSelections[objIndex].commonId = action.payload.commonId;
      newSelections[objIndex].oddsDecimal = action.payload.oddsDecimal;

      const msg = JSON.stringify({
        type: "UPDATE_SELECTION",
        betslip: action.payload,
      });
      if (window && window.parent) {
        window.parent.postMessage(msg, parentURL);
      }
      return {
        ...state,
        selections: newSelections,
      };
    }

    case "CLEAR_BETSLIP": {
      const msg = JSON.stringify({
        type: "REMOVE_SELECTION_FROM_BETSLIP",
        betslip: [],
      });
      if (window && window.parent) {
        window.parent.postMessage(msg, parentURL);
      }

      return {
        selections: [],
        selectionsData: "",
        selectionsCopy: [],
        stake: "10",
        tabsInUse: true,
      };
    }

    case "SET_TAB_STAKE":
      return Object.assign({}, state, {
        stake: action.payload,
        tabsInUse: true,
      });

    case "SET_OTHER_STAKE":
      return Object.assign({}, state, {
        stake: action.payload,
        tabsInUse: false,
      });

    case "SET_STAKE":
      return Object.assign({}, state, {
        stake: action.payload,
      });

    case "RESET_STAKE":
      return Object.assign({}, state, {
        stake: "10",
        tabsInUse: true,
      });

    default:
      return state;
  }
};

export default betslip;
