const initState = {
  type: "open",
  openlimit: 20,
  settledlimit: 5,
  editBets: false
};

const yourbets = (state = initState, action) => {
  switch (action.type) {
    case "SET_BET_TYPE":
      return Object.assign({}, state, {
        type: action.payload
      });

    case "SET_OPEN_LIMIT":
      return Object.assign({}, state, {
        openlimit: action.payload
      });

    case "SET_SETTLED_LIMIT":
      return Object.assign({}, state, {
        settledlimit: action.payload
      });

    case "EDIT_YOUR_BETS":
      return Object.assign({}, state, {
        editBets: action.payload
      });

    default:
      return state;
  }
};

export default yourbets;
