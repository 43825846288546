const initState = {
  brand: "bethub",
  prevPage: "",
  faTipsCategory: "",
  activeOddsFormat: "fractional", // Set as fractional by default in case no active odds format is returned from the origin
};

const app = (state = initState, action) => {
  switch (action.type) {
    case "SET_BRAND":
      return Object.assign({}, state, {
        brand: action.payload,
      });

    case "SET_PREV_PAGE":
      return Object.assign({}, state, {
        prevPage: action.payload,
      });

    case "SET_FA_TIPS_CATEGORY":
      return Object.assign({}, state, {
        faTipsCategory: action.payload,
      });

    case "SET_ACTIVE_ODDS_FORMAT":
      return Object.assign({}, state, {
        activeOddsFormat: action.payload,
      });

    default:
      return state;
  }
};

export default app;
