import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *, *::before, *:after {
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
    -webkit-text-size-adjust: none;
  }
  body {
    background: rgb(17,23,29);
    font-family: Quicksand, sans-serif;
    margin: 0;
    font-size: 1.6rem;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: rgb(21, 32, 44);
      border-radius: 4px;
      border: none;
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      background: rgb(30,42,58);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 8px;
    }

  }
  h1 {
    font-size: 4rem;
    font-weight: bold;
  }
  h2 {
    font-size: 3.2rem;
    font-weight: bold;
  }
  h3 {
    font-size: 2.4rem;
    line-height: 1;
    font-weight: bold;
  }
  h4 {
    font-size: 1.6rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1.2rem;
    font-weight: bold;
  }
  a {
    text-decoration: none;
  }
  .link-text {
    font-size: 1.4rem;
    font-weight: bold;
    color: #b6cbd8;
  }
  .body-text {
    color: rgb(21, 32, 44);
  }
  p {
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    font-weight: normal;
  }
  img {
    max-width: 100%;
    height: auto;
    align-self: center;
  }
  button,
  select {
    font-family: Quicksand, sans-serif;
  }
  .icon {
    width: 2.4rem;
    height: auto;
    &.close, &.back {
      cursor: pointer;
    }
  }
  .capitalize {
    text-transform: capitalize;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .disabled-text {
    color: rgb(56, 66, 77);
  }
  .font-regular {
    font-weight: 400;
  }
  .space-right {
    margin-right: 0.6rem;
  }
  .space-left {
    margin-left: 0.6rem;
  }
  .margin-right {
    margin-right: 1.2rem;
  }
  .margin-left {
    margin-left: 1.2rem;
  }
  .margin-left-base {
    margin-left: 1.8rem;
  }
  .submenu-icon {
    width: 3.8rem;
    height: 3.8rem;
    margin-right: 0.8rem
  }
  .submenu-logo {
    width: 10.8rem;
    height: 1.2rem;
  }
  .submenu-text {
    font-size: 1.2rem;
    color: rgb(182,203,216);
    margin: 0.4rem 0 0;
  }
  .ptr, .ptr-children {
    -webkit-overflow-scrolling: auto;
  }
  .ptr {
    padding-bottom: 8.5rem;
  }
  .flex-center {
    display: flex;
    justify-content: center;
  }
  .flex-center-margin-top {
    display: flex;
    justify-content: center;
    margin-top: 1.2rem;
  }
  .no-stats-text,
  .no-results-text {
    font-size: 1.4rem;
    font-weight: bold;
    color: rgb(182,203,216);
  }
  .footnote {
    text-align: center;
  }
  .link-text-small {
    color: #b6cbd8;
  }
`;

export default GlobalStyle;
