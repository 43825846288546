import styled, { keyframes } from "styled-components";
import LoadingIcon from "assets/general/Sprite_FA.png";
import StadiumImage from "assets/football/Stadium_Background@2x.png";
import StadiumImageDesktop from "assets/football/Stadium_Background@3x.png";

export const AppWrapper = styled.div`
  background: ${(props) => props.theme.pageBackground};
  color: ${(props) => props.theme.white};
  max-width: 102.4rem;
  margin: 0 auto;
`;

export const PageWrapper = styled.div`
  background: ${(props) =>
    props.matchpreview
      ? `url(${StadiumImage}) no-repeat`
      : props.theme.pageBackground};
  background-size: ${(props) => (props.matchpreview ? "auto" : "")};
  background-position-x: ${(props) => (props.matchpreview ? "center" : "")};
  color: ${(props) => props.theme.white};
  max-width: 102.4rem;
  margin: 0 auto;

  @media (min-width: 750px) {
    background: ${(props) =>
      props.matchpreview ? `url(${StadiumImageDesktop}) no-repeat` : ""};
    background-size: ${(props) => (props.matchpreview ? "auto" : "")};
    background-position-x: ${(props) => (props.matchpreview ? "center" : "")};
    background-position-y: ${(props) => (props.matchpreview ? "20%" : "")};
  }
`;

export const Button = styled.button`
  font-size: 1.2rem;
  color: ${(props) =>
    props.featured ? props.theme.white : props.theme.defaultButtonText};
  background-color: ${(props) =>
    props.featured ? props.theme.secondaryBackground : props.theme.white};
  border: none;
  border-radius: 0.8rem;
  padding: 1.6rem;
  cursor: pointer;
  font-weight: bold;
`;

export const OddsButton = styled(Button)`
  display: flex;
  width: 100%;
  font-weight: bold;
  border-radius: 0.4rem;
  padding: 1rem 0.8rem;
  background-color: ${(props) =>
    props.active ? props.theme.formDraw : props.theme.white};

  > div {
    flex: 1 auto;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
`;

export const StickyHeader = styled.header`
  position: ${(props) => (props.discover ? "" : "sticky")};
  top: 0;
  z-index: 2;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  background: ${(props) => props.theme.pageBackground};
  color: ${(props) => props.theme.white};
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.statCardBackground};
  padding: 1.6rem;
  align-items: center;
  justify-content: center;

  > h3 {
    font-weight: normal;
    max-width: 70%;
    margin: 2.4rem auto;
  }
  > h4 {
    padding: 0;
    margin: 0;
  }
  > div {
    img {
      cursor: pointer;
    }
  }

  > div {
    display: flex;
    flex: 1 1 auto;

    a {
      display: inherit;
      span {
        font-size: 1.2rem;
        color: #fff;
      }
      @media (min-width: 750px) {
        span {
          font-size: 1.6rem;
        }
      }
    }

    &:nth-child(1) {
      justify-content: flex-start;
    }

    &:nth-child(2) {
      justify-content: center;
    }

    &:nth-child(3) {
      justify-content: flex-end;
      width: 50%;
      max-width: 30%;
    }
  }

  button {
    height: 4.4rem;
    width: 8.8rem;
    padding: 0;
  }

  .header-col {
    flex: 1 1 auto;

    &:nth-child(1) {
      text-align: left;
    }

    &:nth-child(2) {
      text-align: center;
    }
  }

  .dropdown {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.6rem;

  button {
    margin-bottom: 1.6rem;
  }

  .forgot-password {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
  }

  form {
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonRow = styled(ButtonContainer)`
  flex-direction: row;
  margin: 1.6rem 1.6rem 7.2rem;
  button {
    flex: 1 1;

    &:nth-child(1) {
      margin-right: 1.2rem;
    }
  }
`;

export const Form = styled.form`
  margin: 1.6rem;
`;

export const FormInputContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-bottom: 1.6rem;

  label,
  .label {
    font-size: 1.2rem;
    margin-bottom: 0.6em;
  }

  span {
    font-size: 1.2rem;
  }

  a {
    color: ${(props) => props.theme.secondaryText};
  }

  .react-datepicker {
    font-family: Quicksand, sans-serif;
    font-size: 1.4rem;
  }

  .react-datepicker__input-container {
    input {
      width: 100%;
      height: 4.4rem;
      border-radius: 0.4rem;
      border: 0;
      background-color: ${(props) => props.theme.pillBackground};
      color: ${(props) => props.theme.white};
      padding-left: 1.2rem;
    }
  }

  .react-datepicker__header {
    padding-top: 0;
  }

  .react-datepicker__navigation--previous {
    left: 5px;
  }

  .react-datepicker__navigation--next {
    right: 5px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header {
    font-size: 1.4rem;
    margin: 0.6rem 0;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.4em;
    line-height: 2.4rem;
  }
`;

export const FormCheckboxInputContainer = styled(FormInputContainer)`
  flex-direction: row;
  align-items: center;

  > div {
    &:nth-child(2) {
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-end;
    }
  }
`;

export const FormInput = styled.input`
  height: 4.4rem;
  border-radius: 0.4rem;
  border: 0;
  background-color: ${(props) => props.theme.pillBackground};
  color: ${(props) => props.theme.white};
  padding-left: 1.2rem;
`;

export const FormCheckboxInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + label {
    background: ${(props) => props.theme.formWin};
    border: 1px solid transparent;

    &:after {
      left: calc((100% - 2.4rem));
      border: 0.5px solid white;
    }
  }
`;

export const FormCheckboxInputLabel = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 4.8rem;
  height: 2.6rem;
  background: transparent;
  display: block;
  border-radius: 4.8rem;
  border: 1px solid ${(props) => props.theme.platinum};
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2.4rem;
    height: 2.4rem;
    background: ${(props) => props.theme.platinum};
    border-radius: 2.4rem;
    transition: 200ms;
  }

  &:active {
    &:after {
      width: 2.4rem;
    }
  }
`;

export const PillButton = styled.button`
  font-size: 1.2rem;
  flex: 1 auto 12rem;
  background-color: ${(props) =>
    props.active ? props.theme.formDraw : props.theme.secondaryBackground};
  color: ${(props) =>
    props.active ? props.theme.secondaryBackground : props.theme.secondaryText};
  padding: 0.8rem 1.6rem;
  border-radius: 2.5rem;
  border: none;
  margin: 0 0.8rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  white-space: nowrap;
`;

export const StatsButton = styled.button`
  display: flex;
  flex: 1 auto;
  align-items: center;
  padding: 1.6rem;
  background: ${(props) => props.theme.secondaryBackground};
  border: none;
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
  width: 100%;
  cursor: pointer;

  > img {
    margin-right: 1.2rem;
  }

  .title {
    color: ${(props) => props.theme.white};
    font-weight: bold;
  }

  > div {
    display: flex;
    flex: 1 auto;
    justify-content: flex-end;
    align-items: center;

    .hide,
    .view {
      color: ${(props) => props.theme.secondaryText};
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.6rem 1.6rem;

  > img {
    margin-right: 0.8rem;
  }
`;

export const Dropdown = styled.div`
  background: ${({ theme }) => theme.secondaryBackground};
  margin-bottom: 8px;
  border-radius: 8px;
  > header {
    display: flex;
    align-items: center;
    padding: 1.6rem;
    font-size: 1.2rem;
    cursor: pointer;

    > img {
      margin-right: 0.8rem;
    }

    > div {
      display: flex;
      flex: 1 auto;

      &:nth-child(2) {
        justify-content: flex-end;
      }
    }

    > span {
      font-weight: 700;
      margin: 0;
    }
  }
`;

export const ScrollWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  flex-wrap: nowrap;
  margin: 0.8rem 0;
  padding-bottom: 8px;
  &::-webkit-scrollbar {
    height: 6px;
    margin-top: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(21, 32, 44);
    border-radius: 4px;
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: rgb(30, 42, 58);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
  @media (min-width: 768px) {
    margin-bottom: 1.6rem;
  }

  > span {
    min-width: 100%;
    max-height: 35rem;
    transition: transform 0.5s ease-out;

    @media (min-width: 768px) {
      min-width: 76.8rem;
      margin-right: 1.6rem;
    }
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    margin: 0 0.8rem;
    white-space: nowrap;

    > img {
      margin-right: 1.6rem;
    }

    > span {
      font-weight: 500;
      font-size: 1.4rem;
    }
  }

  .submenu-button {
    margin: 0 0.4rem;
    padding: 1.2rem 0.8rem;
    align-items: flex-end;
  }
`;

export const ScrollIndicators = styled.ul`
  display: flex;
  margin: 0 0 0 1.6rem;
  padding: 0;
  align-items: center;
  justify-content: center;

  > li {
    list-style-type: none;
    border-radius: 50%;
    background: ${(props) => props.theme.pillBackground};
    width: 0.4rem;
    height: 0.4rem;
    margin-right: 0.8rem;
  }

  .active {
    background: ${(props) => props.theme.white};
  }
`;

export const Panel = styled.div`
  background: ${(props) => props.theme.secondaryBackground};
  padding: 1.6rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.white};
`;

const loadingX = keyframes`
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -1920px;
  }`;

const loadingY = keyframes`
  0% {
    background-position-y: 0px;
  }
  100% {
    background-position-y: -800px;
  }`;

export const LoadingSpinner = styled.div`
  position: absolute;
  top: ${(props) => (props.stats ? `${props.pageYOffset}px` : "42.5%")};
  left: 0;
  right: 0;
  width: 160px;
  height: 160px;
  background: url(${LoadingIcon}) no-repeat;
  animation: ${loadingX} 0.2s steps(12) infinite,
    ${loadingY} 1s steps(5) infinite;
  margin: 0 auto;
  transform: scale(0.5) translate(0, -50%);
`;

const spinner = keyframes`
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }`;

export const MiniLoadingSpinner = styled.div`
  color: ${(props) => props.theme.white};
  font-size: 0.6rem;
  border-radius: 50%;
  text-indent: -9999px;
  height: 0.6rem;
  width: 0.6rem;
  margin: auto;
  position: relative;
  transform: translateZ(0);
  /*  Animation */
  animation: ${spinner} 1s infinite linear;
  -webkit-backface-visibility: hidden;
  -webkit-transform: scale(1);
`;

export const StatsCardFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.2rem;

  .update-text {
    position: absolute;
    top: 0;
    right: 1.6rem;
    color: ${(props) => props.theme.secondaryText};
    font-size: 0.8rem;
  }
`;

export const Alert = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  background: ${(props) => props.theme.fadedBackground};
`;

export const AlertContainer = styled.div`
  position: relative;
  color: ${(props) => props.theme.white};
  max-width: 50rem;
  width: 100%;
  margin: 0 2.4rem;
  padding: 2.4rem 1.2rem 1.2rem;
  background-color: ${(props) => props.theme.secondaryBackground};
`;

export const AlertContainerInner = styled.div`
  flex-direction: column;
  justify-content: center;

  h4 {
    margin: 0 0 2.4rem;
  }

  .label-container {
    margin-bottom: 0.6rem;
  }

  label {
    font-size: 1.2rem;
  }

  img {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    cursor: pointer;
  }

  input {
    width: 100%;
    margin-bottom: 0.6rem;
  }

  p {
    font-size: 1.4rem;
  }
`;

export const AlertButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.4rem;

  button {
    width: 100%;

    &:nth-child(2) {
      margin-left: 1.6rem;
    }
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 1.2rem 0;
  > img {
    margin: 0.8rem;
  }
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Tab = styled.div`
  width: 50%;
  position: relative;
  text-align: center;
  cursor: pointer;
  span {
    font-size: 1.4rem;
    display: block;
    text-decoration: none;
    padding: 1.2rem 0 1.6rem;
    width: 100%;
    color: ${(props) => props.theme.white};
  }
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: ${(props) =>
      props.active ? props.theme.formDraw : props.theme.statCardBackground};
    transition: background-color 0.5s ease-in-out;
  }
`;

export const FormHelpRow = styled.div`
  display: flex;
  margin: 0 1.6rem 2.4rem;

  > div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    a {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 1.2rem;
      font-weight: bold;
      color: ${(props) => props.theme.white};
      margin-right: 0.8rem;
    }

    img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;

export const VersionNumber = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 1.2rem;
`;

const SlideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }`;

export const FloatingButtonContainer = styled.div`
  animation: ${SlideUp} 0.75s;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  margin: 1.6rem auto 0;
  padding: 1.6rem;
  max-width: 102.4rem;
  background: ${(props) => props.theme.secondaryBackground};
  z-index: 2;
  border-radius: 8px;
`;

export const FloatingButton = styled.button`
  width: 100%;
  height: 5.6rem;
  border: 0;
  border-radius: 0.4rem;
  background: ${(props) => props.theme.formDraw};
  color: ${(props) => props.theme.defaultButtonText};
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
  cursor: pointer;

  > div {
    display: flex;
    flex: 1;

    &:nth-child(2),
    &:nth-child(3) {
      flex: 1;
    }

    &:nth-child(1) {
      flex: unset;
      margin-right: 0.8rem;
    }

    &:nth-child(2) {
      font-size: 1.2rem;
    }

    &:nth-child(3) {
      justify-content: flex-end;
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  .circle {
    background: ${(props) => props.theme.secondaryBackground};
    color: ${(props) => props.theme.white};
    border-radius: 50%;
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    font-weight: bold;
  }
`;
