import axios from "axios";
import Qs from "qs";
import store from "../reducers";
const ROOT_API = process.env.REACT_APP_API_ENDPOINT;
const SECONDARY_API = process.env.REACT_USER_API_ENDPOINT;
const FA_TIPS_API = process.env.REACT_FA_TIPS_API_ENDPOINT;


/* ----------------------- */
/* Betting
/* ----------------------- */

export const getInitialBetData = () =>
  axios.get(`${ROOT_API}/smartacca/selector`);

export const getSmartAccaResults = (
  bookmaker,
  market,
  competitions,
  basedOn,
  orderBy
) =>
  axios({
    url: `${ROOT_API}/smartacca/fixtures?bookmaker=${bookmaker}&market=${market}&comp=${competitions}&basedOn=${basedOn}&order=${orderBy}`,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });

export const getSmartAccaResultsAllComps = (
  bookmaker,
  market,
  competitions,
  basedOn,
  orderBy
) =>
  axios({
    url: `${ROOT_API}/smartacca/fixtures?bookmaker=${bookmaker}&market=${market}&comp=&basedOn=${basedOn}&order=${orderBy}`,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });

export const getSmartAccaResultsByDate = (
  bookmaker,
  market,
  competitions,
  basedOn,
  orderBy,
  date
) =>
  axios({
    url: `${ROOT_API}/smartacca/fixtures?bookmaker=${bookmaker}&market=${market}&comp=${competitions}&basedOn=${basedOn}&order=${orderBy}&date=${date}`,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });

export const getFeaturedBets = compid => {
  let url = `${ROOT_API}/smartacca/football/featured?v=20191108`;

  if (compid) {
    url = url + `&comp=${compid}`;
  }

  return axios({
    url,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });
};

export const getInPlayMatchBets = (bookmaker, market, compid) =>
  axios({
    url: `${ROOT_API}/smartacca/football/live?bookmaker=${bookmaker}&market=${market}&comp=${compid}&v=20191108`,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });

export const getUpcomingMatchBets = (bookmaker, market, compid) =>
  axios({
    url: `${ROOT_API}/smartacca/football/upcoming?bookmaker=${bookmaker}&market=${market}&comp=${compid}&v=20191108`,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });

export const getMatchFixtures = (date, bookmaker, market, compid) =>
  axios({
    url: `${ROOT_API}/smartacca/football/fixtures/${date}?bookmaker=${bookmaker}&market=${market}&comp=${compid}&v=20191108`,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });

export const getBetOdds = (token, selections) =>
  axios({
    url: `${ROOT_API}/smartacca/odds?selections=${selections
      .map(item => `${item.eventid}%3B${item.commonId}`)
      .join()}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });

export const getYourBets = (token, status, after, limit) =>
  axios({
    url: `${ROOT_API}/smartacca/bets/${status}?after=${after}&limit=${limit}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });

export const deleteBetFromYourBets = (token, payload) =>
  axios({
    url: `${ROOT_API}/smartacca/myBets?id=${payload}`,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    data: payload
  });

export const getBetOffers = () =>
  axios({
    url: `https://api.fantasyiteam.com/public/v1.7/getPromos?sessionid=1`,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });

export const getMatchStats = (fixtureid, market) =>
  axios({
    url: `${ROOT_API}/fixtures/${fixtureid}/stats?market=${market}`,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });

export const toggleMatchNotifications = (payload, token) => {
  return axios({
    url: `${ROOT_API}/notifications/match`,
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    },
    data: payload
  });
};

/* ----------------------- */
/* FA Tips
/* ----------------------- */
export const getFATips = () =>
  axios({
    url: `${FA_TIPS_API}/fa/tips`,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });

export const getFATipDetails = query =>
  axios({
    url: `${ROOT_API}/smartacca/tip?query=${query}`,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });

/* ----------------------- */
/* Match Preview
/* ----------------------- */
export const getMatchPreview = (fixtureid, bookmaker) =>
  axios({
    url: `${ROOT_API}/smartacca/fixtures/${fixtureid}/preview?bookmaker=${bookmaker}`,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });

/* ----------------------- */
/* Discover
/* ----------------------- */
export const getDiscoverContent = () =>
  axios({
    url: `${ROOT_API}/smartacca/discover`,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  });

/* ----------------------- */
/* Quiz
/* ----------------------- */
export const getQuizHome = token =>
  axios({
    url: `${ROOT_API}/quiz/home`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });

export const getQuizQuestions = (token, quizId) =>
  axios({
    url: `${ROOT_API}/quiz/${quizId}/addEntry`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });

export const submitQuizAnswers = (token, payload) =>
  axios({
    url: `${ROOT_API}/quiz/submitAnswers`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    data: payload
  });

export const getQuizLeaderboard = (token, type) =>
  axios({
    url: `${ROOT_API}/quiz/leaderboard/${type}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });

export const getQuizResults = token =>
  axios({
    url: `${ROOT_API}/quiz/results`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
