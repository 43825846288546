const initState = () => ({
  show: false,
  msg: "",
  dispatchAction: null,
  showSameSelectionsAlert: false,
  neverShowSelectionsAlert:
    localStorage.getItem("hideselectionsalert") !== null,
});

const alert = (state = initState(), action) => {
  switch (action.type) {
    case "CLOSE_ALERT":
      return Object.assign({}, state, {
        show: false,
        msg: "",
      });

    case "OPEN_ALERT":
      return Object.assign({}, state, {
        show: true,
        msg: action.payload,
      });

    case "SET_SHOW_SAME_SELECTIONS_ALERT":
      return Object.assign({}, state, {
        showSameSelectionsAlert: action.payload,
      });

    case "SET_ALERT_DISPATCH":
      return Object.assign({}, state, {
        dispatchAction: action.payload,
      });

    case "SET_NEVER_SHOW_SAME_SELECTIONS_ALERT": {
      localStorage.setItem("hideselectionsalert", true);

      return Object.assign({}, state, {
        neverShowSelectionsAlert: true,
      });
    }

    case "RESET_NEVER_SHOW_SAME_SELECTIONS_ALERT": {
      localStorage.removeItem("hideselectionsalert");

      return Object.assign({}, state, {
        neverShowSelectionsAlert: false,
      });
    }

    default:
      return state;
  }
};

export default alert;
