export const ALERT = {
  invalidJWTMsg: "Your session has expired. Please login to continue",
  invalidSessionMsg: "Authentication token expired, Please login again",
  invalidJWTCode: 6,
  invalidSessionCode: 1,
};

export const defaultTheme = {
  themeName: "dark",
  pageBackground: "rgb(17, 23, 29)",
  darkJungleGreen: "rgb(21, 32, 44)",
  secondaryBackground: "rgb(21, 32, 44)",
  secondaryText: "rgb(182, 203, 216)",
  fadedBackground: "rgba(17, 23, 29, 0.5)",
  defaultText: "#FFF",
  white: "#fff",
  black: "#000",
  defaultButtonText: "#222",
  defaultButtonBg: "#FFF",
  selected: "rgb(1, 248, 131)",
  pillText: "#FFF",
  lineBreak: "rgb(30, 42, 58)",
  probabilityHighlight: "rgb(68, 219, 94)",
  selectedPill: "rgb(239, 229, 46)",
  selectedText: "rgb(21, 32, 44)",
  pillBackground: "rgb(56,66,71)",
  yellow: "rgb(239, 229, 46)",
  formLoss: "rgb(231, 32, 53)",
  formDraw: "rgb(239, 229, 46)",
  formWin: "rgb(68, 219, 94)",
  statsFormLoss: "rgb(70, 39, 57)",
  statsFormDraw: "rgb(72, 79, 55)",
  statsFormWin: "rgb(38, 77, 65)",
  indicator: "rgb(56, 66, 77)",
  facebook: "rgb(59, 89, 152)",
  twitter: "rgb(85, 172, 238)",
  platinum: "rgb(225, 225, 225)",
  tip: "rgb(24, 60, 97)",
  tipBorder: "rgb(2, 137, 255)",
  statCardBackground: "rgb(30, 42, 58)",
  statScoreText: "rgb(19, 31, 46)",
};

export const STANDARD_FORMAT_MARKETS = [
  "AGS",
  "FGS",
  "H+1",
  "H+2",
  "H-1",
  "H-2",
  "H-2",
  "OU05",
  "OU15",
  "OU25",
  "OU35",
  "OU45",
  "OU55",
  "OU65",
  "OU75",
  "P1+SOT",
  "P2+SOT",
  "P3+SOT",
  "P1+FOULS",
  "P2+FOULS",
  "P3+FOULS",
  "P4+FOULS",
  "FPTBC",
  "PTBC",
  "PTBSO",
  "TCOU55",
  "TCOU65",
  "TCOU75",
  "TCOU85",
  "TCOU95",
  "TCOU105",
  "TCOU115",
  "TCOU125",
  "TCOU135",
  "HCOU25",
  "HCOU35",
  "HCOU45",
  "HCOU55",
  "HCOU65",
  "HCOU75",
  "HCOU85",
  "ACOU25",
  "ACOU35",
  "ACOU45",
  "ACOU55",
  "ACOU65",
  "ACOU75",
  "ACOU85",
];
